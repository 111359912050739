import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins';
import App from './App.vue'
import router from './router'
import store from './store';
import axios from 'axios';
import i18n from './plugins/i18n'
import VueAnalytics from 'vue-analytics'

Vue.config.productionTip = false

let hash = (Math.floor(Math.random() * 1E16));
localStorage.getItem('visitor_id') ? localStorage.getItem('visitor_id') : localStorage.setItem('visitor_id', `SHOP-${hash}`);
localStorage.getItem('lang')?localStorage.getItem('lang'):localStorage.setItem('lang',process.env.VUE_APP_I18N_LOCALE);

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('shop_token');
  const metaVisitor = document.querySelector("meta[name=visitor]");
  const visitor = metaVisitor ? metaVisitor.getAttribute("content") : localStorage.getItem('visitor_id');
  
  config.headers.Authorization =  token;  
  config.headers['X-Shop-Visitor'] =  visitor;
  config.headers['X-Shop-Store'] =  store.getters['Store/store'].slug || '';
  config.headers['X-Shop-Lang'] = localStorage.getItem('lang');
  
  if(process.env.VUE_APP_HOST){
    config.headers['X-Shop-host'] = process.env.VUE_APP_HOST || '';
  }

  let company = store.getters['Store/company'];

  if(!company && localStorage.getItem('company')){
    company = JSON.parse(localStorage.getItem('company'));
  }
  config.headers['X-Shop-Company'] = company ? company.slug : '';

  return config;
});

// FILTERS VUE
Vue.filter('dinheiro', valor => {
	return `${parseFloat(valor).toFixed(2)}Є`.replace('.', ',')
})

Vue.use(VueAnalytics, {
  id: 'UA-120906088-3',
  router,
  ecommerce: {
    enabled: true,
    enhanced: true
  },
  /*debug: {
    enabled: true, // default value
    trace: true, // default value
    sendHitTask: true // default value
  }*/
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
