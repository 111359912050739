import axios from "axios";

export default {
    setOrder(context, value) {
        context.commit('setCart', value);        
    },
    async loadOrder(context, payload) {        
        context.dispatch("Site/loading", true, {root: true}) 
        
        return await axios({
            method: 'GET',
            url: 'order',
            params: {
                hash: payload,
            },
            baseURL: process.env.VUE_APP_BASE_URL_API,
        })
        .then(response => response.data)
        .then(data => {            
            context.commit('setOrder', data.data);
            context.dispatch("Site/loading", false, {root: true});

            if(!context.getters['Store/store']){
                context.dispatch('Store/loadStore', data.data.store.slug, {root: true})
            }            
            
            return data.data;
        });
    },

    async requestPayment(context, payload) {        
        context.dispatch("Site/loading", true, {root: true}) 
        
        return await axios({
            method: 'POST',
            url: `order/${payload}/renew-payment`,           
            baseURL: process.env.VUE_APP_BASE_URL_API,
        })
        .then(response => response.data)
        .then(data => {            
            context.commit('setOrder', data.data);
            context.dispatch("Site/loading", false, {root: true});
            context.dispatch('Store/loadStore', data.data.store.slug, {root: true})
            
            return data.data;
        });
    },
};
