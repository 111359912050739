import axios from "axios";

export default {
    setCompositionsSelected(context, value){
        let productModal = context.state.productModal;
        
        if(!('compositionsSelected' in productModal)){
            productModal.compositionsSelected = {};
        }
        productModal.compositionsSelected = Object.assign(productModal.compositionsSelected, value)

        context.commit('setCompositionsSelected', productModal);
    },
    removeCompositionsSelected(context, params) {
        let productModal = context.state.productModal;
        
        productModal.compositionsSelected[params.menuId] = productModal.compositionsSelected[params.menuId].filter(i => i.product_ref_id !=params.product_ref_id)

        if(
            params.menuId in productModal.compositionsSelected &&
            productModal.compositionsSelected[params.menuId].length == 0
        ){
            delete productModal.compositionsSelected[params.menuId];
        }

        context.commit('setProductModal', productModal);     
    },
    setproductsSuggested(context, value) { 
        context.commit('setproductsSuggested', value);
    },
    setFreebee(context, value) { 
        context.commit('setFreebee', value);
    },
    setActiveCart(context, value) {
        context.dispatch('Site/setActiveBackdrop', value, {root: true});
        context.commit('setActiveCart', value);
    },
    setProductModal(context, value) {
        if(value.total == undefined){
            value.total = parseFloat(value.price);
        }
        context.commit('setProductModal', value);        
    },
    setValueProductCompositions(context, value) {
        context.commit('setValueProductCompositions', value); 
        let productModal = context.state.productModal;        
        productModal.total = parseFloat(productModal.price) + parseFloat(value);
        context.commit('setProductModal', productModal); 
    },
    setCart(context, value) {
        context.commit('setCart', value);        
    },
    async loadCart(context) {        
        context.dispatch("Site/loading", true, {root: true}) 
        
        return await axios({
            method: 'GET',
            url: 'cart',
            baseURL: process.env.VUE_APP_BASE_URL_API,
        })
        .then(response => response.data)
        .then(data => {            
            context.commit('setCart', data.data);
            context.dispatch("Site/loading", false, {root: true});
            
            return data.data;
        });
    },
    async updateItem(context, formData){
        context.dispatch("Site/loading", true, {root: true});

        return await axios({
            method: 'POST',
            url: `cart/update-item`,
            baseURL: process.env.VUE_APP_BASE_URL_API,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => response.data)
        .then(data => {
            context.dispatch("Site/loading", false, {root: true});
            context.commit('setCart', data.data); 
            
            return data;
        });
    },
    async addItem(context, formData){
        context.dispatch("Site/loading", true, {root: true});

        return await axios({
            method: 'POST',
            url: `cart/add-item`,
            baseURL: process.env.VUE_APP_BASE_URL_API,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => response.data)
        .then(data => {
            context.dispatch("Site/loading", false, {root: true});
            context.commit('setCart', data.data); 
            
            return data;
        });
    },
    async removeItem(context, formData){
        context.dispatch("Site/loading", true, {root: true});

        await axios({
            method: 'DELETE',
            url: `cart/remove-item`,
            baseURL: process.env.VUE_APP_BASE_URL_API,
            data: formData,            
        }).then(response => response.data.data)
        .then(data => {
            context.dispatch("Site/loading", false, {root: true});
            context.commit('setCart', data);            
        });
    }
};
