import axios from "axios";

export default {
    async loadDataGroup(context) {       
        context.dispatch("Site/loading", true, {root: true}) 
        
        return axios({
            method: 'GET',
            url: 'group',
            baseURL: process.env.VUE_APP_BASE_URL_API,            
        })
        .then(response => response.data)
        .then(data => {            
            context.commit('setGroup', data.data);
            context.dispatch("Site/loading", false, {root: true});
            
            return data.data;
        });
    },

    async loadDataCompany(context, slug) {       
        context.dispatch("Site/loading", true, {root: true}) 
        
        return axios({
            method: 'GET',
            url: 'company',
            params: {
                slug
            },
            baseURL: process.env.VUE_APP_BASE_URL_API,
            headers: {
                'X-Shop-Company-Header': slug, //forçar update no multidb
            }
        })
        .then(response => response.data)
        .then(data => {            
            context.commit('setCompany', data.data);
            context.dispatch("Site/loading", false, {root: true});
            
            return data.data;
        });
    },
    
    async loadStore(context, payload) {       
        context.dispatch("Site/loading", true, {root: true}) 
        
        return axios({
            method: 'GET',
            url: `store/${payload}`,
            baseURL: process.env.VUE_APP_BASE_URL_API,
            //usando no interceptors
            // headers: {
            //     'X-Shop-Company': context.getters.company.slug,
            // }
        })
        .then(response => response.data)
        .then(async data => {                        
            context.commit('setStore', data.data);
            context.dispatch("Site/loading", false, {root: true});
            await context.dispatch('loadCampaigns');
            
            return data.data;
        });
    },
    async loadCalendarDelivery(context, payload) {       
        context.dispatch("Site/loading", true, {root: true}) 
        
        return axios({
            method: 'GET',
            url: `store/calendar-delivery/${payload}`,
            baseURL: process.env.VUE_APP_BASE_URL_API,
        })
        .then(response => response.data)
        .then(async data => {                        
            context.commit('setCalendar', data.data);
            context.dispatch("Site/loading", false, {root: true});

            return data.data;
        });
    },
    async loadCampaigns(context, payload) {       
        context.dispatch("Site/loading", true, {root: true}) 
        
        let url = `campaigns`;
        url = payload ? `${url}/${payload}` : url;

        return axios({
            method: 'GET',
            url: url,
            baseURL: process.env.VUE_APP_BASE_URL_API,
        })
        .then(response => response.data)
        .then(data => {            
            context.commit('setCampaigns', data.data);
            context.dispatch("Site/loading", false, {root: true});
            
            return data.data;
        });
    },
    loadSuggestedProducts(context,payload){
        return axios({
            method: 'GET',
            url: `/product/suggested/${payload}`,
            baseURL: process.env.VUE_APP_BASE_URL_API,
        })
        .then(response => response.data);
    }

};
