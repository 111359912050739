import axios from "axios";
import { io } from "socket.io-client";

export default {
    setMenuOpen(context, value) {
        context.commit('setActiveBackdrop', value);
        context.commit('setMenuOpen', value);
    },
    setActiveBackdrop(context, value) {
        context.commit('setActiveBackdrop', value);
    },
    loading(context, value) {
        context.commit('setLoading', value)
    },
    setIsDesktop(context, value) {
        context.commit('setIsDesktop', value);
    },
    async registerWebsocket(context) {
        let userId = localStorage.getItem('visitor_id');
        await axios({
            method: 'POST',
            url: 'websocket',
            baseURL: process.env.VUE_APP_BASE_URL_API,            
        }).then(response => response.data)
        .then(data => {
            console.log(data)
            const socket = io(data.websocket_url, {
                reconnect: true,
                transports: ['websocket', 'polling', 'flashsocket']
            });
            
            socket.on("connect", function () {
                console.log("Connect -> Socket", userId)
                // socket.emit('register', userId, "general");
                socket.emit('register', 'message');
            });

            socket.on('message', function (payload) {
                console.log("Receive message =>");
                console.log(payload)
                if ('store' in payload) {
                    let params = 'params' in payload ? payload.params : {};

                    context.dispatch(payload.store, params, {
                        root: true
                    });
                }
            })

            socket.on('disconnect', function (socket) {
                socket.socket.reconnect();
            });

            context.commit("setSocket", socket);
        });
    }
};
