import Vue from 'vue'
import VueRouter from 'vue-router'
import Page404 from '../views/404.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */'../views/Home')
  },
  {
    path: '/restaurant/:slug',
    name: 'Restaurant',
    component: () => import(/* webpackChunkName: "restaurant" */'../views/Restaurant')
  },
  {
    path: '/store/:slug',
    name: 'Store',
    component: () => import(/* webpackChunkName: "restaurant" */'../views/Store')
  },
  {
    path: '/checkout/:slug',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "checkout" */'../views/Checkout')
  },
  {
    path: '/order/:hash',
    name: 'Order',
    component: () => import(/* webpackChunkName: "order" */'../views/Order')
  },
  {
    path: '/404',
    name: 'Page404',
    component: Page404
  },
  {
    path: '*',
    redirect: '/404'
  },
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      console.log("moving to top of the page");
      window.scrollTo(0, 0);
    }
  }
})

export default router
